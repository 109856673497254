import React, { useEffect, useState } from 'react';
import ajaxSession from '../../Utils/remote/ajaxSession';
import HomeCard from '../HomeCard';

function AvailableSpaceCard(props) {

    const [totalAvailableSpace, setTotalAvailableSpace] = useState("...");
    const [totalSpace, setTotalSpace] = useState("...");
    const [refreshCounter, setRefreshCounter] = useState(0);


    useEffect(()=>{
        countStat()
    }, [refreshCounter]);


    useEffect(()=>{

        setTimeout(() => {
            setRefreshCounter(refreshCounter+1)
        }, 3000);

    }, [refreshCounter])



   
   




    const countStat=async()=>{
        const server_response = await ajaxSession.countAvailableParking();
        if(server_response.status==="OK")
        {
            setTotalAvailableSpace(server_response.details.available_slots.total_p)
            setTotalSpace(server_response.details.total_slots.total_p)

        }
    
    }



    return (
        <HomeCard 
        title="Available Space" 
        value={totalAvailableSpace}
        icon="fa fa-parking"
        color="primary"
        bottomDescription="Total Slots"
        bottomValue={totalSpace}
        link="#"
        />
       
    );
}

export default AvailableSpaceCard;