import React from "react";
import ajaxStreet from "../../Utils/remote/ajaxStreet";
import AlertError from "../Common/AlertError";
import AlertSuccess from "../Common/AlertSuccess";
import TextInput from "../Common/TextInput";

class CreateStreet extends React.Component{

    state={
        streetName:"",
        info:""
    }


    handleStreetName=(event)=>{

        this.setState({
            streetName : event.target.value
        })
    }

  

    createStreet = async(event)=>{

        event.preventDefault();

        const { streetName} = this.state;

        if(streetName.length>0)
        {
            //post to api
            const server_response = await ajaxStreet.createStreet(streetName);
            if(server_response.status==="OK")
            {
                this.setState({
                    streetName:"",
                    info: <AlertSuccess message={server_response.message}/>
                })
                this.props.onRefresh()

            }

        }else{

            this.setState({
                info: <AlertError message="Complete all fields and try again"/>
            })
        }
        
        

    }


    render(){
    return(
        <div className="container">
        <button type="button" class="btn btn-info btn-md" style={{float:"right",fontSize:"14px",color:"yellow !important"}} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      <i className="fa fa-plus"></i> Add Street 
    </button>
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Add new street</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            {this.state.info}
            <form method="post" onSubmit={this.createStreet}>



            <TextInput
                type="text"
                label='Street Name'
                value={this.state.streetName}
                placeholder="Enter Street name"
                onChange={()=>this.handleStreetName}
                />

                <div class="modal-footer mt-4">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-success">Create Street</button>
                </div>
            </form>

      </div>
      
    </div>
  </div>
</div>
</div>

    )

}
}

export default CreateStreet