import React from "react";
import AlertError from "../Components/Common/AlertError";
import AlertSuccess from "../Components/Common/AlertSuccess";
import TextInput from "../Components/Common/TextInput";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxLocation from "../Utils/remote/ajaxLocation";
import ajaxStreet from "../Utils/remote/ajaxStreet";

class LocationPage extends React.Component{

    state={
        streetId:"",
        locationId:"",
        locationName:"",
        totalSlots:"",
        longitude:"",
        latitude:"",
        info:"",
        streetListing:false,
        locationListing:false
    }


    componentDidMount(){
            this.listLocation();
            this.listStreets();
    }

  


    setStreet=(e)=>{
        this.setState({streetId:e.value})
    }

    handleLocationName=(event)=>{
        this.setState({
            locationName : event.target.value
        })
    }

    handleSlotChange =(e)=>{
        this.setState({totalSlots: e.target.value})
    }

    handleLngChange =(e)=>{
        this.setState({longitude: e.target.value})

    }

    handleLatChange =(e)=>{
        this.setState({latitude: e.target.value})
    }



    listStreets=async()=>{

        const server_response = await ajaxStreet.getStreets();
        if(server_response.status==="OK")
        {
            this.setState({
                streetListing:server_response.details
            })
        }
    }


    listLocation=async()=>{
        const server_response = await ajaxLocation.getLocation();
        if(server_response.status==="OK")
        {
            this.setState({
                locationListing:server_response.details
            })
        }

    }


    createLocation = async(event)=>{

        event.preventDefault();

        const { streetId, locationName, latitude, longitude, totalSlots} = this.state;

        if(streetId>0 && locationName.length>0)
        {
            //post to api
            const server_response = await ajaxLocation.createLocation(streetId, locationName, totalSlots, latitude, longitude);
            if(server_response.status==="OK")
            {
                this.setState({
                    locationName:"",
                    totalSlots:"",
                    latitude:"",
                    longitude:"",
                    info: <AlertSuccess message={server_response.message}/>
                })
                this.listLocation();
            }

        }else{

            this.setState({
                info: <AlertError message="Complete all fields and try again"/>
            })
            //error
        }
        
        

    }


    RenderStreet=()=>{

        const optionStreets = [];

        // const streetList=[
        //     {"street_id":"1", "street_name":"Kimathi Avenue"},
        //     {"street_id":"2", "street_name":"Colville Street"}

        // ];

        if (Array.isArray(this.state.streetListing)) {
            this.state.streetListing.map((streetInfo, index) =>
                    optionStreets.push({ label: streetInfo.street_name, value: streetInfo.street_id })
            )
        }

       

        return(
            <TextInput
            label="Select street"
            type="select" 
            placeholder="Select street...." 
            options={optionStreets}
            onChange={this.setStreet }
            />
        )
    }


    render(){


      

        return(
           <AppContainer
            title="Location"
           >
            <div className="container">

            
           <button type="button" class="btn btn-info btn-md" style={{float:"right",fontSize:"14px",color:"yellow !important"}} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      Add Location 
      <i className="fa fa-plus"></i>
    </button>

       

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add new location</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      {this.state.info}
      <form method="post" onSubmit={this.createLocation}>



      <this.RenderStreet/>

           
<TextInput
    type="text"
    label='Location Name'
    value={this.state.locationName}
    placeholder="Enter Location name"
    onChange={()=>this.handleLocationName}
    />

    <TextInput
    type="number"
    label='Total Slots'
    value={this.state.totalSlots}
    placeholder="Enter number of slots"
    onChange={()=>this.handleSlotChange}
    required
    />

<TextInput
    type="text"
    label='Geo Latitude'
    value={this.state.latitude}
    placeholder="Enter Geo Latitude"
    onChange={()=>this.handleLatChange}
    required
    />


<TextInput
    type="text"
    label='Geo Longitude'
    value={this.state.longitude}
    placeholder="Enter Geo Longitude"
    onChange={()=>this.handleLngChange}
    required
    />

     <div class="modal-footer mt-4">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-success">Create Location</button>
      </div>
 </form>

      </div>
      
    </div>
  </div>
</div>
           
<div className="table-responsive">

                <table border={3} className="table table-light table-hover table-bordered border-light mt-4 mb-4">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Street Name</th>
                            <th>Location Name</th>
                            <th>Total Slots</th>
                            <th>Longitude</th>
                            <th>Latitude</th>
                            
                            
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(this.state.locationListing) && this.state.locationListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item.street.street_name}</td>
                                <td>{item.location_name}</td>
                                <td>{item.total_slots}</td>
                                <td>{item.lat}</td>
                                <td>{item.long}</td>
                                

                            </tr>
                        )}

                    </tbody>
                </table>
                </div>

            
               
                        <div>
                            
                        </div>





                </div>

           </AppContainer>
        )
    }
}

export default LocationPage
