import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ajaxSession from '../../Utils/remote/ajaxSession';
import ActivityLoader from '../Common/ActivityLoader';
import TableHeader from '../Common/TableHeader';

function RecentSessions(props) {


    const [recentSessions, setRecentSessions] = useState("...");
    const [refreshCounter, setRefreshCounter] = useState(0);


    useEffect(()=>{
        getRecentSessions();
    }, [refreshCounter]);


    useEffect(()=>{

        setTimeout(() => {
            setRefreshCounter(refreshCounter+1)
        }, 3000);

    }, [refreshCounter])



    const getRecentSessions=async()=>{
        const server_response = await ajaxSession.getRecentSessions();
        if(server_response.status==="OK")
        {
            setRecentSessions(server_response.details)

        }else{
            setRecentSessions("404")
        }
    
    }


    return (
        <div className="card custom-card ">
            <div className="card-body map-card">

            <TableHeader
                title="Recent Sessions"
                subtitle="Recently parked vehicles sorted according to the most recent"
                view="View All"
                viewButton={<a href="/session" className="btn btn-primary btn-sm" style={{float:"right"}}>View All</a>}
            />

            <div className="border-top mt-3"></div>
            <div className="user-manager scroll-widget border-top overflow-auto">
                <div className="table-responsive">
                    <table className="table table-hover text-nowrap mg-b-0">   
                        <thead>
                                <tr>
                                <th>Start Time</th>
                                <th>Vehicle</th>
                                <th>Location</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(recentSessions) && recentSessions.map((item, key)=>
                                <tr key={key}>
                                    <td>{item.start_time.short_date + " " + item.start_time.time}</td>
                                    <td>
                                      <Link to={`vehicle-profile/${item.plate_number}`} > {item.plate_number}</Link> <br/>
                                    <small class="text-muted">{item.slots.total_p}</small>
                                    
                                    </td>
                                    <td>
                                        {item.location.location_name}<br/>
                                        <small class="text-muted">{item.location.street.street_name}</small>
                                    </td>
                                    <td>
                                        <span className={`badge bg-${item.active*1===1?"success":"danger"}`}>
                                            {item.active*1===1?"Active":"Ended"}
                                        </span>
                                
                                    </td>
                                </tr>
                                
                            )}

                        </tbody>
                    </table>
                    {!recentSessions && <ActivityLoader/>}
                </div>
            </div>
        </div>
    </div>
    );
}

export default RecentSessions;