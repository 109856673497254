import React from "react";
import { Link } from "react-router-dom";
import ajaxUser from "../Utils/remote/ajaxUser";
// import logo1 from '../images/new(1).jpeg';
import logo2 from '../images/RentCar.png';
import AlertSuccess from "../Components/Common/AlertSuccess";
import AlertError from "../Components/Common/AlertError";


class Login extends React.Component{

    state={
      username:"",
      password:"",
      info:""
    }

    
    login=async(event)=>{
      event.preventDefault();
      const {username, password} = this.state;

      if(username.length>0 && password.length>0){
          //call api
          const server_response = await ajaxUser.login(username, password);
          if(server_response.status==="OK")
          {
            //store token
            localStorage.setItem('scpps@user', server_response.details);
            // //redirect user
            window.location.reload();
          
            this.setState({
              info:<AlertSuccess message={server_response.message}/>
            })

          }else{
            this.setState({
              info: <AlertError message={server_response.message}/>
            })
          }
      }else{
        this.setState({
          info: <AlertError message="Enter username and password"/>
        })
      }
    }

    handlePassword=(e)=>{
      this.setState({
        password:e.target.value
      })
    }

    handleUsername=(e)=>{
        this.setState({
          username:e.target.value
        })
    }

    render(){

          return (

            <div className="body page">
              
                      {/* <img src={logo} className="header-brand-img" alt="" style={{height:"120px",weight:"20px"}}/> */}

            <div className="">
            {/* <img src={logo} className="header-brand-img" alt="" style={{height:"120px",weight:"20px"}}/> */}
               
               
               
                <div className="container-login100">
                    <div className="wrap-login100 p-6">
                        <form className="login100-form validate-form" method="post" onSubmit={this.login}> 


                        <div className="col col-login mx-auto mt-3">
                          <div className="text-center">
                            <img src={logo2} className="header-brand-img" alt=""/>
                          </div>
                      </div>
                            
                            <span className="login100-form-title pb-5 pt-2"> Login </span>
                            <span className="mb-3">{this.state.info}</span>

                            <div className="panel panel-primary">
                               
                                <div className="panel-body tabs-menu-body p-0 pt-5">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab5">
                                            <div className="wrap-input100 validate-input input-group"> 
                                                <Link
                                                    to="#"
                                                    className="input-group-text bg-white text-muted" > <i
                                                        className="zmdi zmdi-email text-muted" aria-hidden="true"></i> 
                                                  </Link>

                                                <input 
                                                    className="input100 border-start-0 form-control ms-0" 
                                                    type="text"
                                                    placeholder="Username"
                                                    required
                                                    onChange={this.handleUsername}
                                                    value={this.state.username}
                                                     /> 
                                              </div>

                                              <div className="wrap-input100 validate-input input-group" id="Password-toggle">
                                                <Link to="#"
                                                    className="input-group-text bg-white text-muted"> <i
                                                        className="zmdi zmdi-eye text-muted" aria-hidden="true"></i> 
                                                  </Link>
                                                <input 
                                                    className="input100 border-start-0 form-control ms-0" 
                                                    type="password"
                                                    placeholder="Password" 
                                                    required
                                                    onChange={this.handlePassword}
                                                    value={this.state.password}
                                                    /> 
                                              </div>
                                            
                                            <div className="text-end pt-4">
                                                <p className="mb-0"><Link to=""
                                                        className="text-primary ms-1">Forgot Password?</Link></p>
                                            </div>
                                            <div className="container-login100-form-btn"> 
                                                <button type="submit" className="login100-form-btn btn-primary"> Login </button> </div>
                                            
                            
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

          )
              
        
    }
}

export default Login;