import React from "react";
import ActivityLoader from "../Components/Common/ActivityLoader";
import AlertError from "../Components/Common/AlertError";
import AlertSuccess from "../Components/Common/AlertSuccess";
import TextInput from "../Components/Common/TextInput";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxStreet from "../Utils/remote/ajaxStreet";
import CreateStreet from "../Components/Street/CreateStreet";
class StreetPage extends React.Component{

    state={
        id:"",
        streetId:"",
        streetListing:false,
        countListing:false,
        info:"",
        
    }


    componentDidMount(){
            this.listStreets();
            
            
    }

    listStreets=async()=>{

        const server_response = await ajaxStreet.getStreets();
        console.log(server_response)
        console.log("Preparing to fetch street")

        if(server_response.status==="OK")
        {
            console.log("Fetching streets again")
            this.setState({
                streetListing:false
            }, ()=>{
                this.setState({
                    streetListing:server_response.details
                })
            })
        }else{
            this.setState({
                info:<AlertError message={server_response.message}/>,
                streetListing:"404"
            })
        }

    }
    
    


    confirmDelete = async(street_id)=>{


        if(street_id>0)
        {
            //post to api
            const server_response = await ajaxStreet.deleteStreet(street_id);
            if(server_response.status==="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.message}/>
                })
                this.listStreets();
            }else{
                this.setState({
                    info: <AlertError message={server_response.message}/>
                })
            }

        }else{

            this.setState({
                info: <AlertSuccess message={"Complete all fields"}/>,
                
            })
    
            this.listStreets()
    
        }
    
    
    }



    render(){


        return(
           <AppContainer
            title="Streets"
           >

            <div className="row">
                <div className="col-12">
 
    <CreateStreet />
    
       


                <div className="table-responsive ">
                    {this.state.info}
                <table border={3} className="table table-light table-hover table-bordered border-light mt-4">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Street Name</th>
                            

                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(this.state.streetListing) && this.state.streetListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item.street_name}</td>
                                <td>
                                <button className="btn btn-danger" type="button" onClick={()=>this.confirmDelete(item.street_id)}>Delete</button>
                                </td>
                                
                            </tr>
                            
                        )}

                    </tbody>
                </table>
                </div>




                </div>
                
                </div>


               

           </AppContainer>
        )
    }
}

export default StreetPage