const functions={

getHost(){
    
    switch(process.env.REACT_APP_ENVIRONMENT)
    {
        case "PRODUCTION":
            return process.env.REACT_APP_PRODUCTIONURL
        case "TEST":
            return process.env.REACT_APP_TESTURL

        default:
            return process.env.REACT_APP_DEVELOPMENTURL
    }
    
},

removeClassFromElementSiblings(elem, selectorClass) {

    // Setup siblings array and get the first sibling
    // var siblings = [];
    var sibling = elem.parentNode.firstChild;

    // Loop through each sibling and push to the array
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            sibling.classList.remove(selectorClass)
        }
        sibling = sibling.nextSibling
    }

},


}


export default functions