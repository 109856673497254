import React from 'react';
import { TailSpin } from  'react-loader-spinner'
class ActivityLoader extends React.Component {
  

  render(){
     
    const height=this.props.height || 80
    const width=this.props.width || 100
    const color=this.props.color || "#6c5ffc"
    const customClass=this.props.customClass || "col-xl-12 col-lg-12 col-12 text-center mt-5 mb-5"
      return (
        <span className={customClass}>
             <TailSpin
                color={color}
                height={height}
                width={width}
                ariaLabel='loading'
            />
        </span>

      );
    
 
  }
}

export default ActivityLoader