import React from "react";
import { Link } from "react-router-dom";
import functions from "../Utils/functions";
import logo from '../images/RentCar.png';


const LeftSideBar = ()=>{


    const toggleMenu = (e) => {
        e.preventDefault();
        // if (e.target.parentElement.nodeName === "A") {

        //     functions.removeClassFromElementSiblings(e.target.parentElement.offsetParent, 'is-expanded')
        //     e.target.parentElement.offsetParent.classList.toggle('is-expanded');
        // } else {
            // console.log(e.target.parentElement.parentElement)
            functions.removeClassFromElementSiblings(e.target.parentNode.parentElement, 'is-expanded')
            e.target.parentNode.parentElement.classList.toggle('is-expanded');
        // }

    }

    return(
        <>
        <div className="sticky is-expanded stickyClass">
        <div className="app-sidebar__overlay active" data-bs-toggle="sidebar"></div>
        <div className="app-sidebar ps open">
            <div className="side-header"> <a className="header-brand1" href="index.html"> 
                        <img
                        src={logo} className="header-brand-img desktop-logo"
                        alt="logo"/> 
                        <img src={logo}
                        className="header-brand-img toggle-logo" alt="logo"/> 
                        <img
                        src={logo} className="header-brand-img light-logo"
                        alt="logo"/> <img src={logo}
                        className="header-brand-img light-logo1" alt="logo"/> 
                        </a>
            </div>
            <div className="main-sidemenu is-expanded">
                <div className="slide-left disabled active is-expanded" id="slide-left"><svg
                        xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z">
                        </path>
                    </svg></div>
                <ul className="side-menu open" style={{marginRight: "0px", marginLeft: "0px"}}>
                    <li className="sub-category">
                        <h3>Dashboards</h3>
                    </li>
                    <li className="slide is-expanded"> <Link className="side-menu__item has-link active"
                            data-bs-toggle="slide" to="/"><span
                                className="side-menu__label"><i className="fas fa-car"></i> Operations</span></Link> 
                    </li>
                    <li className="slide is-expanded"> <Link className="side-menu__item has-link active"
                            data-bs-toggle="slide" to="/invoice"><span
                                className="side-menu__label"><i className="fas fa-hand-holding-usd"></i>  Finance</span></Link> 
                    </li>
                    <li className="sub-category">
                        <h3>Applications</h3>
                    </li>
                    <li className="slide is-expanded"> <Link className="side-menu__item has-link active"
                            data-bs-toggle="slide" to="/session"><span
                                className="side-menu__label"><i className="fas fa-clock"></i> Sessions</span></Link> 
                    </li>
                    <li className="slide is-expanded"> <Link className="side-menu__item has-link active"
                            data-bs-toggle="slide" to="/parking"><span
                                className="side-menu__label"><i className="fas fa-parking"></i> Parking Space</span></Link> 
                    </li>
                    <li className="slide is-expanded"> <Link className="side-menu__item has-link active"
                            data-bs-toggle="slide" to="/configuration"><span
                                className="side-menu__label"><i className="fas fa-cog"></i>  Configurations</span></Link> 
                    </li>
                    <li className="slide is-expanded"> 
                            <Link 
                                className="side-menu__item" data-bs-toggle="slide"
                                to="#" onClick={toggleMenu}>
                                
                                <span
                                className="side-menu__label"><i className="fas fa-book"></i>  Reports</span>
                                <i className="angle fe fe-chevron-right"/>
                            </Link>
                        <ul className="slide-menu">
                            <li><Link to="/vehicle/balances" className="slide-item"> Outstanding Balances</Link></li>
                            <li><Link to="#" className="slide-item"> Street Analysis</Link></li>
                            <li><Link to="#" className="slide-item"> Session Tracking</Link></li>
                        </ul>
                    </li>
                   
                </ul>
              
            </div>
           
        </div>
    </div>
    {/* <div className="jumps-prevent" style={{marginTop:"100px"}}></div> */}
</>
    )
}


export default LeftSideBar