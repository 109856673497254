import React from "react";
import {Link} from 'react-router-dom'

const Footer =()=>{

    return(
        <footer className="footer">
        <div className="container">
            <div className="row align-items-center flex-row-reverse">
                <div className="col-md-12 col-sm-12 text-center"> Copyright © <span id="year">2022</span> 
                <Link to="#">Smart City Parking System</Link>. Designed with <span
                        className="fa fa-heart text-danger"></span> by <Link to="#"> BSE22-7 </Link> All
                    rights reserved. </div>
            </div>
        </div>
    </footer>
    )
}

export default Footer