import React, { useEffect, useState } from 'react';
import ajaxSession from '../../Utils/remote/ajaxSession';
import HomeCard from '../HomeCard';

function SessionCard(props) {

    const [totalDailySession, setTotalDailySessions] = useState("...");
    const [totalActiveSession, setTotalActiveSessions] = useState("...");
    const [refreshCounter, setRefreshCounter] = useState(0);


    useEffect(()=>{
        countSession();
        countActiveSessions();
    }, [refreshCounter]);


    useEffect(()=>{

        setTimeout(() => {
            setRefreshCounter(refreshCounter+1)
        }, 3000);

    }, [refreshCounter])



   
   




    const countSession=async()=>{
        const server_response = await ajaxSession.countSession();
        if(server_response.status==="OK")
        {
            setTotalDailySessions(server_response.details.total_p)

        }
    
    }


    const countActiveSessions=async()=>{

        const server_response = await ajaxSession.countActiveSessions();
        if(server_response.status==="OK")
        {
            setTotalActiveSessions(server_response.details.total_p)

        }
    }


    return (
        <HomeCard 
        title="Active Sessions" 
        value={totalActiveSession}
        icon="fa fa-clock"
        color="success"
        bottomDescription="Total Session Today"
        bottomValue={totalDailySession}
        link="#"
        />
    );
}

export default SessionCard;