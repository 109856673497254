import React from "react";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxLocation from "../Utils/remote/ajaxLocation";

class ParkingPage extends React.Component{
  state={
    Location_Listing:false
  }

  componentDidMount(){
    this.listLocation();

  }
      listLocation=async()=>{
  
          const server_response = await ajaxLocation.getLocation();
          if(server_response.status==="OK")
          {
              this.setState({
                  Location_Listing:server_response.details
              })
          }
  
      }

    render(){
        return(
           <AppContainer
            title="Parking Space"
           >

<table class="table bg-white">
  <thead>
    <tr>
      <th scope="col">No.</th>
      <th scope="col">Street Name</th>
      <th scope="col" >Location Name</th>
      <th scope="col">Total Slots</th>
      <th scope="col">Available Slots</th>
    </tr>
  </thead>
  <tbody>

  {Array.isArray(this.state.Location_Listing)&& this.state.Location_Listing.map((item,key)=>
    <tr key={key}>
    <th scope="row">{key+1}</th>
    <td>{item.street.street_name}</td>
    <td>{item.location_name}</td>
    <td>{item.total_slots}</td>
    {/* {{if({item.})

    
    }} */}
    <td>{item.available_slots.total_c}</td>
  </tr>
    )}
  </tbody>
</table>


           </AppContainer>
        )
    }
}

export default ParkingPage