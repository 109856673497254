import React from 'react';


class InitialAvatar extends React.Component {
  state={
    sizes:['', 'avatar-l', 'avatar-xl', 'avatar-xxl'],
    size:this.props.size || 3
  }

  render(){
    
      return (
        <div className={`avatar ${this.state.sizes[this.state.size]} d-none d-sm-flex ${this.props.background || 'bg-dark'}`}>
            {this.props.value}
		</div>
      );
    
 
  }
}

export default InitialAvatar;