import React from 'react';
import { Link } from 'react-router-dom';

function Notification(props) {
    return (
        <div className="dropdown  d-flex notifications"> 
            <Link to="" className="nav-link icon" data-bs-toggle="dropdown"><i className="fe fe-bell"></i>
                <span className=" pulse"></span> 
            </Link>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <div className="drop-heading border-bottom">
                        <div className="d-flex">
                            <h6 className="mt-1 mb-0 fs-16 fw-semibold text-dark">
                                Notifications </h6>
                        </div>
                    </div>
                    <div className="notifications-menu ps">   
                        <div className="ps__rail-x" style={{left: "0px", bottom: "0px"}}>
                            <div className="ps__thumb-x" tabIndex="0"
                                style={{left: "0px", width: "0px"}}></div>
                        </div>
                        <div className="ps__rail-y" style={{top: "0px", right: "0px"}}>
                            <div className="ps__thumb-y" tabIndex="0"
                                style={{top: "0px", height: "0px"}}></div>
                        </div>
                    </div>
                    <div className="dropdown-divider m-0"></div> <a href="notify-list.html"
                        className="dropdown-item text-center p-3 text-muted">View all
                        Notification</a>
                </div>
            </div>
    );
}

export default Notification;