import React from "react";
import AppContainer from "../Components/Structure/AppContainer";
import HomeCard from "../Components/HomeCard";
import ajaxInvoice from "../Utils/remote/ajaxInvoice";
import TableHeader from "../Components/Common/TableHeader";
class FinancePage extends React.Component{

    state={
        invoiceId:"",
        invoiceListing:false,
        countListing:false
    }


    componentDidMount(){
            this.listInvoice();
            this.countInvoice();
            
            
    }


    setInvoice=(e)=>{

        this.setState({invoiceId:e.value})
    }

    handleInvoiceChange=(e)=>{

    }


    listInvoice=async()=>{

        const server_response = await ajaxInvoice.getInvoice();
        console.log(server_response.details)
        if(server_response.status==="OK")
        {
            this.setState({
                invoiceListing:server_response.details
            })


        }

    }

    countInvoice=async()=>{

        const server_response = await ajaxInvoice.countInvoice();
        console.log(server_response.details)
        if(server_response.status==="OK")
        {
            this.setState({
                countListing:server_response.details
            })


        }

    }


    render(){


      

        return(
           <AppContainer
            title="Finance"
           >
               
            <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                        <div class="row">

                        <HomeCard 
                            title="Invoices Today" 
                            value={this.state.countListing.total_p}
                            icon="fa fa-clock"
                            color="success"
                            bottomDescription="Total Invoices"
                            bottomValue=""
                            link="#"
                            />
                        
                        <HomeCard 
                            title="Parking Revenue" 
                            value="20000"
                            icon="fa fa-car"
                            color="danger"
                            bottomDescription="This month"
                            bottomValue=""
                            link=""
                            />



                </div>
            </div>

            <div className="container">
 
            <TableHeader
                title="Recent Invoices"
                subtitle="Recently generated invoices in the order of the most recent"
                view="View All"
            />

            <div className="border-top mt-3"></div>
            <div className="user-manager scroll-widget border-top overflow-auto">
       




                <div className="table-responsive ">
                <table border={3} className="table table-light table-hover table-bordered border-light mt-4">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Invoice Type</th>
                            <th>Transaction Ref</th>
                            <th>Plate Number</th>
                            <th>Invoice Amount</th>
                            <th>Invoice Balance</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(this.state.invoiceListing) && this.state.invoiceListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item.invoice_type.type_name}</td>
                                <td>{item.transaction_ref}</td>
                                <td>{item.vehicle.plate_number}</td>
                                <td>{item.total_amount.amount_c}</td>
                                <td>{item.invoice_balance.amount_c}</td>
                                

                            </tr>
                            
                        )}

                    </tbody>
                </table>
                </div>
            </div>
                
                </div>


            {/* </div> */}

               

           </AppContainer>
        )
    }
}

export default FinancePage