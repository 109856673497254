import React from "react";
import ActivityLoader from "../Components/Common/ActivityLoader";
import TextInput from "../Components/Common/TextInput";
import AlertError from "../Components/Common/AlertError";
import AlertSuccess from "../Components/Common/AlertSuccess";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxWorkingHours from "../Utils/remote/ajaxWorkingHours";

class WorkingHoursPage extends React.Component{

    state = {
        openingTime:"",
        closingTime:""
    }

    componentDidMount(){
        // this.listLocation();
        // this.listStreets();
}


handleOpeningTime=(event)=>{
    this.setState({
        openingTime : event.target.value
    })
}

handleClosingTime=(event)=>{
    this.setState({
        closingTime : event.target.value
    })
}

createWorkingHours = async(event)=>{

    event.preventDefault();

    const { openingTime, closingTime} = this.state;

    if(openingTime.length>0 && closingTime.length>0)
    {
        //post to api
        const server_response = await ajaxWorkingHours.createWorkingHours(openingTime, closingTime);
        if(server_response.status==="OK")
        {
            this.setState({
                openingTime:"",
                closingTime:"",
                info: <AlertSuccess message={server_response.message}/>
            })
        }

    }else{

        this.setState({
            info: <AlertError message="Complete all fields and try again"/>
        })
        //error
    }

}

    render(){
        return(
        <AppContainer title="Work Hours">
            <div className="container">
            <div className="card">
                <div className="card-header">
                    Parking Hours
                </div>
                <div className="card-body" style={{fontSize:"18px",marginLeft:"20px"}}>
                {this.state.info}
      <form method="post" onSubmit={this.createWorkingHours}>
                <div class="mb-3 row">
                    <label class="col-sm-2 col-form-label">Opening Time</label>
                    <div class="col-sm-10">
                    <input type="time" class="form-control" onChange={this.handleOpeningTime}/>
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-2 col-form-label">Closing Time</label>
                    <div class="col-sm-10">
                    <input type="time" class="form-control" onChange={this.handleClosingTime}/>
                    </div>
                </div>

                    <button type="submit" style={{float:"right"}} class="btn btn-primary mb-3">Update Hours</button>
           </form>
            </div>
            </div>
        
            </div>

            
        </AppContainer>
        )
    }
}

export default WorkingHoursPage