import React from 'react';


class AlertSuccess extends React.Component {
  

  render(){
    
      return (
        <div className="alert alert-success mg-b-10" role="alert">
            <strong>Success! </strong>{this.props.message}
        </div>

      );
    
 
  }
}

export default AlertSuccess;