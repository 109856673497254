import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('scpps@user');
const apiHost=dictionary.apiHost

const ajaxVehicle={

   async getVehicle() {
     try {
       let response = await fetch(apiHost + "vehicle/list",
         {
           method: 'GET',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           }
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   },
   async getVehicleOwner(owner_id) {
    let data = {
      owner_id: owner_id
    }
    try {
      let response = await fetch(apiHost + "vehicle/owner",
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },
   async countVehicle() {
    try {
      let response = await fetch(apiHost + 'vehicle/count',
        {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },





  async countNewVehicle() {
    try {
      let response = await fetch(apiHost + 'vehicle/new/count',
        {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  }







      

}


export default ajaxVehicle
