import React from "react";
import AlertError from "../Components/Common/AlertError";
import AlertSuccess from "../Components/Common/AlertSuccess";
import TextInput from "../Components/Common/TextInput";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxUser from "../Utils/remote/ajaxUser";
import ajaxRole from "../Utils/remote/ajaxRole";

class UsersPage extends React.Component{

    state={
        userId:"",
        first_name:"",
        last_name:"",
        username:"",
        role_id:"",
        info:"",
        userListing:false,
        roleListing:false
    }


    componentDidMount(){
            this.listUsers();
            this.listRoles();
    }

    setRole=(e)=>{
        this.setState({role_id:e.value})
    }


    handleFirstName=(event)=>{

        this.setState({
            first_name : event.target.value,
        })
    }

    handleLastName=(event)=>{

        this.setState({
            last_name : event.target.value,
        })
    }
    handleUserName=(event)=>{

        this.setState({
            username : event.target.value,
        })
    }

    listRoles=async()=>{

        const server_response = await ajaxRole.getRole();
        if(server_response.status==="OK")
        {
            this.setState({
                roleListing:server_response.details
            })
        }

    }


    listUsers=async()=>{

        const server_response = await ajaxUser.getUser();
        if(server_response.status==="OK")
        {
            this.setState({
                userListing:server_response.details
            })
        }

    }
    createUser = async(event)=>{

        event.preventDefault();

        const { first_name, last_name, username, role_id} = this.state;

        if(first_name.length>0 && last_name.length>0 && username.length>0 && role_id>0)
        {
            //post to api
            const server_response = await ajaxUser.createUser(username, first_name, last_name, role_id);
            if(server_response.status==="OK")
            {
                this.setState({
                    first_name:"",
                    last_name:"",
                    username:"",
                    info: <AlertSuccess message={server_response.message}/>
                })
                this.listUsers();
            }

        }else{

            this.setState({
                info: <AlertError message="Complete all fields and try again"/>
            })
        }
        
        

    }
    RenderRole=()=>{

        const optionRoles = [];

        if (Array.isArray(this.state.roleListing)) {
            this.state.roleListing.map((roleInfo, index) =>
                    optionRoles.push({ label: roleInfo.role_name, value: roleInfo.role_id })
            )
        }

       

        return(
            <TextInput
            label="Select role"
            type="select" 
            placeholder="Select role...." 
            options={optionRoles}
            onChange={this.setRole }
            />
        )
    }
    handleDeactivate=async(item)=>{
        
        
        
        const server_response = await ajaxUser.deactivateUser(item.user_id);
        console.log(item.user_id);
        if(server_response.status==="OK")
         {
            
             this.setState({
                 info: <AlertSuccess message={server_response.message}/>,
                
             })

             this.listUsers()

        }

    
}
handleActivate=async(item)=>{
        
    //post to api
    const server_response = await ajaxUser.activateUser(item.user_id);
    console.log(item.user_id);
    if(server_response.status==="OK")
    {
        
        this.setState({
            info: <AlertSuccess message={server_response.message}/>,
            
        })

        this.listUsers()

    }


}



    render(){
        



        return(
           <AppContainer
            title="Users"
           >

            <div className="container">
 
    <button type="button" class="btn btn-info btn-md" style={{float:"right",fontSize:"14px",color:"yellow !important"}} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      Add User 
      <i className="fa fa-plus"></i>
    </button>

       

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add new user</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      {this.state.info}
      <form method="post" onSubmit={this.createUser}>



 <TextInput
     type="text"
     label='First Name'
     value={this.state.first_name}
     placeholder="Enter User First Name"
     onChange={()=>this.handleFirstName}
     />

<TextInput
     type="text"
     label='Last Name'
     value={this.state.last_name}
     placeholder="Enter User Last Name"
     onChange={()=>this.handleLastName}
     />

<TextInput
     type="text"
     label='User Name'
     value={this.state.username}
     placeholder="Enter Username"
     onChange={()=>this.handleUserName}
     />

<this.RenderRole/>



     <div class="modal-footer mt-4">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-success">Create User</button>
      </div>
 </form>

      </div>
      
    </div>
  </div>
</div>

                <div className="table-responsive ">
                <table border={3} className="table table-light table-hover table-bordered border-light mt-4">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    {Array.isArray(this.state.userListing) && this.state.userListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.username}</td>
                                <td>
                                <div>
      {item.is_active == 1 ? (
        <p>ACTIVE</p>
      ) : (
        <p>DISABLED</p>
      )}


    </div>
                                </td>
                                <td>
    {item.is_active == 1 ? (
            <button type="button" class="btn btn-danger" onClick={()=>this.handleDeactivate(item)} style={{fontSize:"14px"}}>
            De-Activate
          </button>
      ) : (
        <button type="button" class="btn btn-success" onClick={()=>this.handleActivate(item)} style={{fontSize:"14px"}}>
        Activate
      </button>
      )}
    </td>


                            </tr>
                        )}
                            
                                    
                            
                        

                    </tbody>
                </table>

                </div>
                
                </div>


            {/* </div> */}

               

           </AppContainer>
        )
    }
}

export default UsersPage