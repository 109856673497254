import React from "react";
import ActivityLoader from "../Components/Common/ActivityLoader";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxSession from "../Utils/remote/ajaxSession";
import "jspdf-autotable";
// import jsPDF from "jspdf";
import { Link } from "react-router-dom";
class SessionPage extends React.Component{
    state={
        Session_Listing:false
      }

componentDidMount(){
  this.listSession()
}

exportPDF = () => {
//   const unit = "pt";
//   const size = "A4"; // Use A1, A2, A3 or A4
//   const orientation = "portrait"; // portrait or landscape

//   const marginLeft = 40;
//   const doc = new jsPDF(orientation, unit, size);

//   doc.setFontSize(15);

//   const title = "SESSIONS REPORT";
//   const headers = [["Plate Number", "Location","Slots","Start Time","End Time"]];
// console.log(this.state.Session_Listing);
//   const data = this.state.Session_Listing.map(elt=> [elt.plate_number,elt.location.location_name,elt.slots.total_p,elt.start_time.when,elt.end_time.when]);
  
//   let content = {
//     startY: 50,
//     head: headers,
//     body: data
//   };

//   doc.text(title, marginLeft, 40);
//   doc.autoTable(content);
//   doc.save("report.pdf")
}



    listSession=async()=>{

        const server_response = await ajaxSession.getSession();
        console.log(server_response)
        if(server_response.status==="OK")
        {
            this.setState({
                Session_Listing:server_response.details
            })
            console.log(this.state.Session_Listing)
        }else{
          this.setState({
            Session_Listing:"404"
        })
        }

    }

    render(){
        return(
           <AppContainer
            title="Sessions"
           >
             <div className="container">
               <div className="row">
                 <div className="col-md-12">
             {/* <input type="button" style={{float:"right"}} className="btn btn-primary mb-4" value="Generate report pdf" onClick={() => this.exportPDF()}/> */}

                   </div>
               </div>
             </div>
<table className="table bg-white">
  <thead>
    <tr>
      <th scope="col">No.</th>
      <th scope="col">Number Plate</th>
      <th scope="col">Total Slots</th>
      <th scope="col"> Status</th> 
      <th scope="col"> Start Time</th>
      <th scope="col">End Time</th>
    </tr>
  </thead>
  <tbody>

  {Array.isArray(this.state.Session_Listing)&& this.state.Session_Listing.map((item,key)=>
    <tr key={key}>
    <th scope="row">{key+1}</th>
    <td><Link to={`/vehicle-profile/${item.plate_number}`} > {item.plate_number}</Link></td>
    <td>{item.slots.total}</td>
    
    <td>
      <span className={`badge bg-${item.active*1===1?'success':'danger'}`}>
        {item.active *1 ===1?'Active':'Ended'}
      </span>
      </td>
    <td>{item.start_time.when}</td>
    <td>{item.end_time.when}</td>
  </tr>
    )}
    
  </tbody>
</table>
{!this.state.Session_Listing && <ActivityLoader/>}
           </AppContainer>
        )
    }
}

export default SessionPage