import React from "react";
import { Link } from "react-router-dom";

const HomeCard = (props)=>{

    return(
        
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <Link to={props.link}>
        <div className="card">
            <div className="card-body">
                
                <div className="card-order">
                    <h4 className="mb-2"><small>{props.title}</small></h4>
                    <h2 className="text-end">
                        <i className={`${props.icon} text-${props.color} text-${props.color}-shadow border-${props.color} icon-size float-start brround p-3`}></i>
                            <span>{props.value}</span>
                    </h2>
                        <p className="mb-0 pt-5">{props.bottomDescription}<span className="float-end">{props.bottomValue}</span>
                    </p>
                </div>
            </div>
        </div>
        </Link>
    </div>


    )
}

export default HomeCard;