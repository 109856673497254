import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('scpps@user');
const apiHost=dictionary.apiHost

const ajaxLocation={

   async getLocation() {
     try {
       let response = await fetch(apiHost + 'location/list',
         {
           method: 'GET',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           }
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   },

   async countLocation() {
    try {
      let response = await fetch(apiHost + 'location/count',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },


async createLocation(streetId, locationName, slots, latitude, longitude) {
    let data = {
      "location_name": locationName,
      "street_id": streetId,
      "lat":latitude,
      "lng":longitude,
      "total_slots":slots
    }
    try {
      let response = await fetch(apiHost + 'location/create',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },
  async countLocation() {
    try {
      let response = await fetch(apiHost + 'location/count',
        {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  }
}

export default ajaxLocation