import React from 'react'
import AlertError from "../Components/Common/AlertError";
import AlertSuccess from "../Components/Common/AlertSuccess";
import TextInput from "../Components/Common/TextInput";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxClampThreshold from '../Utils/remote/ajaxClampThreshold';

class ClampThresholdPage extends React.Component{
state = {
    thresholdAmount:"",
    clampThresholdListing:false
}

componentDidMount(){
    this.listThreshold()
}


    handleThreshold = (e)=>{
        this.setState({thresholdAmount: e.target.value})
    }

    listThreshold=async()=>{

        const server_response = await ajaxClampThreshold.getThreshold();
        if(server_response.status==="OK")
        {
            this.setState({
                clampThresholdListing:server_response.details
            })
            console.log(server_response)
        }

    }

    createThreshold = async(event)=>{

        event.preventDefault();

        const {thresholdAmount} = this.state;

        if(thresholdAmount.length>0)
        {
            //post to api
            const server_response = await ajaxClampThreshold.createThreshold(thresholdAmount);
            if(server_response.status==="OK")
            {
                this.setState({
                    thresholdAmount:"",
                    info: <AlertSuccess message={server_response.message}/>
                })
                this.listThreshold();

            }

        }else{

            this.setState({
                info: <AlertError message="Complete all fields and try again"/>
            })
            //error
        }

    }
    handleActivate=async(item)=>{
        
        //post to api
        const server_response = await ajaxClampThreshold.activateThreshold(item.amount_id);
        
        if(server_response.status==="OK")
        {
            
            this.setState({
                info: <AlertSuccess message={server_response.message}/>,
                
            })
    
            this.listThreshold()
    
        }
    
    
    }
    handleDeactivate=async(item)=>{
        
        //post to api
        const server_response = await ajaxClampThreshold.deactivateThreshold(item.amount_id);
        
        if(server_response.status==="OK")
        {
            
            this.setState({
                info: <AlertSuccess message={server_response.message}/>,
                
            })
    
            this.listThreshold()
    
        }
    
    
    }

    render(){
        return(
            <AppContainer
            title="Clamp Threshold"
           >
               <div className="container">
               <button type="button" class="btn btn-info btn-md" style={{float:"right",fontSize:"14px",color:"yellow !important"}} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
        Add Threshold <i className="fa fa-plus"></i>
    </button>

       

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Create New Threshold</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      {this.state.info}
      <form method="post" onSubmit={this.createThreshold}>

      <div class="form-group">
 <TextInput
     type="text"
     label='Threshold Amount'
     value={this.state.thresholdAmount}
     placeholder="Enter new threshold amount"
     onChange={()=>this.handleThreshold}
     className="form-control mb-4"
     />
     </div>
     <div class="modal-footer mt-4">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-success">Add</button>
      </div>
 </form>

      </div>
      
    </div>
  </div>
</div>
            <div className="table-responsive">
                <table class="table table-light mt-5">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Clamp Threshold</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Array.isArray(this.state.clampThresholdListing) && this.state.clampThresholdListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item.amount}</td>
                                <td>{item.created_at}</td>
                                <td>
    {item.status == 1 ? (
            <button type="button" class="btn btn-danger" onClick={()=>this.handleDeactivate(item)} style={{fontSize:"14px",float:"right"}}>
            De-Activate
          </button>
      ) : (
        <button type="button" class="btn btn-success" onClick={()=>this.handleActivate(item)} style={{fontSize:"14px",float:"right"}}>
        Activate
      </button>
      )}
    </td>
                            </tr>
                            
                        )}
                    </tbody>
                </table>
            </div>


               </div>
           </AppContainer>
        )
    }
}

export default ClampThresholdPage