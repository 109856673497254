import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('scpps@user');
const apiHost=dictionary.apiHost

const ajaxVehicleProfile={
   async getVehicleDetails(plate_number) {
    let data = {
        "plate_number": plate_number
    }
    
    try {
      let response = await fetch(apiHost + "vehicle/find/plate",
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },
  async updateVehicleDetails(firstName,lastName,phone_number,ownerid) {
    let data = {
        "first_name": firstName,
        "last_name": lastName,
        "phone_number": phone_number,
        "client_id": ownerid
    }
    
    try {
      let response = await fetch(apiHost + "vehicle/client/update",
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },
   async countVehicle() {
    try {
      let response = await fetch(apiHost + 'vehicle/count',
        {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },





  async countNewVehicle() {
    try {
      let response = await fetch(apiHost + 'vehicle/new/count',
        {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  }







      

}


export default ajaxVehicleProfile
