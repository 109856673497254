import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import LeftSideBar from "../LeftSideBar";
import PageHeader from "./PageHeader";

class AppContainer extends React.Component{

    render(){
        return(

            <div className="horizontalMenucontainer open">
            <div className="page">

               <div className="page-main is-expanded">
                   <Header/>
                   <LeftSideBar/>
                   {/* <div class="jumps-prevent" style={{paddingTop:"208.62px"}}></div> */}
                   
                   <div className="main-content app-content mt-9">

                   <div className="side-app">
                       
                           <div className="main-container container-fluid">
                   
                               
                              <PageHeader title={this.props.title}/>

                               <div class="row">
                                    {this.props.children}
                               </div>


                           </div>
                       </div>
               </div>





               </div>

       <Footer/>
       </div>
   </div>
        )
    }

}

export default AppContainer