import React from 'react';


class AlertError extends React.Component {
  

  render(){
    
      return (
        <div className={`alert alert-${this.props.type || "danger"} mg-b-10`} role="alert">
      
            <strong></strong>{this.props.message}
        </div>

      );
    
 
  }
}

export default AlertError;
