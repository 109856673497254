import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('scpps@user');
const apiHost=dictionary.apiHost

const ajaxInvoice={
  async countInvoice() {
    try {
      let response = await fetch(apiHost + 'invoice/count',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async sumInvoice() {
    try {
      let response = await fetch(apiHost + 'invoice/sum',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

   async getInvoice() {
     try {
       let response = await fetch(apiHost + 'invoice/list',
         {
           method: 'GET',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           }
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   },

   async getVehicleInvoice(vehicle_id) {
     let data = {
       vehicle_id: vehicle_id
     }
     try {
       let response = await fetch(apiHost + 'invoice/vehicle',
         {
           method: 'POST',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   },
//    async createStreet(streetName) {
//      let data = {
//        street_name: streetName
//      }
//      try {
//        let response = await fetch(apiHost + 'street/create',
//          {
//            method: 'POST',
//            headers: {
//                'Authorization': 'Bearer ' + ACCESS_TOKEN,
//                'Accept': 'application/json',
//                'Content-Type': 'application/json'
//            },
//            body: JSON.stringify(data),
//          });
//        let result = await response.json();
//        return result;
//      } catch (error) {
//        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
//        return connError;
//      }
//    },



async fetchOutstandingInvoices(){
  let response = await apiCall("invoice/outstanding", []);
  return response;
}


      

}

export default ajaxInvoice
