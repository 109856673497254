import React from 'react';
import {Link} from 'react-router-dom'

const PageHeader =(props)=>{
    return(
        <div className="page-header">
        <h1 className="page-title">{props.title}</h1>
        <div>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
            </ol>
        </div>
    </div>
    )
}

export default PageHeader;