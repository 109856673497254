import React from 'react'
import AppContainer from '../Components/Structure/AppContainer';
import ajaxVehicleType from "../Utils/remote/ajaxVehicleType";

class VehicleType extends React.Component{
    state={
        vehicleTypeId:"",
        vehicleTypeListing:false
    }


    componentDidMount(){
            this.listVehicleType();
    }


    setVehicleType=(e)=>{
        this.setState({vehicleTypeId:e.value})
    }

    handleVehicleTypeChange=(e)=>{

    }


    listVehicleType=async()=>{

        const server_response = await ajaxVehicleType.getVehicleType();
        if(server_response.status==="OK")
        {
            this.setState({
                vehicleTypeListing:server_response.details
            })
        }
        console.log(server_response)

    }


   

       

      
    

    render(){


      

        return(
           <AppContainer
            title="Vehicle Types"
           >

            
           
            


            <div className="row">

                <div className="col-12 card">

                <table border={1} className="table table-striped">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Vehicle Type</th>
                            <th>Slots</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(this.state.vehicleTypeListing) && this.state.vehicleTypeListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item.type_name}</td>
                                <td>{item.slots}</td>
                            </tr>
                        )}

                    </tbody>
                </table>
                </div>

            </div>

               

           </AppContainer>
        )
    }
}

export default VehicleType