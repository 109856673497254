import React, { Component } from 'react';
import Chart from "react-apexcharts";
import axios from 'axios'
import ajaxSession from '../Utils/remote/ajaxSession';
import ActivityLoader from '../Components/Common/ActivityLoader';

class FeesGraph extends Component {

   state={
     testing:false,
     statData:false,

    series: false,

    options: false
   }

   componentDidMount() {
    this.getStatistics()
  }

  setSeries = (sessions) =>{
    this.setState({
      series:[
        {
          name: 'Total Sessions',
          type: 'bar',
          data: sessions
        }]
      
    })
  }

  setOptions = (days)=>{
    this.setState({
      options:{
        chart: {
                // height: 139,
                type: 'line',
                stacked: false,
                toolbar: {
                  show: false
                }
              },
        
              plotOptions: {
                bar: {
                  columnWidth: '30%',
                  // distributed: true,
                }
              },
        
              colors: ['#7c59e6', '#dfe2e6', '#eb6f33'],
        
              stroke: {
                width: [0, 2, 4],
                curve: 'smooth'
              },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        colors: ['#7c59e6', '#eb6f33'],
        stroke: {
          width: [0, 2, 4],
          curve: 'smooth'
        },
        labels: days,
        markers: {
          size: 0
        },
        yaxis: {
          title: {
            // text: 'Points',
            display: false,
          },
          min: 0,
          tickAmount: 7,
          // max: 200,
        },
        legend: {
          display: true,
          position: 'top'
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " Sessions";
              }
              return y;
            }
          }
        },
       
        grid: {
          borderColor: '#f1f1f1'
        },
      },

    })
  }


  getStatistics=async() =>{
     const server_response = await ajaxSession.getWeeklySessionStatistics()
     if(server_response.status === 'OK'){
        this.setState({
          statData:server_response.details
        },()=>{
          const data = this.state.statData
          const weekdays = []
          const sessions = []
          Object.keys(data.days).map((index, key)=>{
            weekdays.push(data.days[index])
          })
          Object.keys(data.totals).map((index, key)=>{
            sessions.push(data.totals[index].total*1)
          })
          this.setOptions(weekdays)
          this.setSeries(sessions)
        }
        )

     }
  }

    render() {
        return (
            <div className="card custom-card overflow-hidden">
                <div className="card-body">
                    <div className="card-option d-flex">
                        <div>
                            <h6 className="card-title mb-1">Weekly Parking Statistics</h6>
                            <p className="text-muted card-sub-title">Number of city street parkings per week</p>
                        </div>
                        <div className="card-option-title ml-auto">
                            <div className="btn-group p-0">
                                <button className="btn btn-light btn-sm" type="button">This Week</button>
                                {/* <button className="btn btn-outline-light btn-sm" type="button">This Year</button> */}
                            </div>
                        </div>
                    </div>
                    <div>


          {this.state.statData && this.state.options && this.state.series && <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    

                                    height={250}
                                    /> }
          {!this.state.statData && <ActivityLoader/>}

                    </div>
                </div>
            </div>
        
        );
    }
}

export default FeesGraph;