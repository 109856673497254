import React from "react";
import {Link} from 'react-router-dom'
import Message from "./HeaderFiles/Message";
import Notification from "./HeaderFiles/Notification";
import UserMenu from "./HeaderFiles/UserMenu";

const Header=()=>{


        return(
            <>
            <div className="app-header header sticky stickyClass fixed-header visible-title">
                    <div className="container-fluid main-container">
                        <div className="d-flex"> 
                            <Link to="#" aria-label="Hide Sidebar" className="app-sidebar__toggle"
                                data-bs-toggle="sidebar"></Link>
                                <Link className="logo-horizontal " to="index.html"> 
                                    <img src="../assets/images/brand/logo.png"
                                    className="header-brand-img desktop-logo" alt="logo"/> 
                                    <img
                                    src="../assets/images/brand/logo-3.png" className="header-brand-img light-logo1"
                                    alt="logo"/> 
                                </Link>
                            <div className="main-header-center ms-3 d-none d-lg-block dropdown"> <input type="text"
                                    className="form-control dropdown-toggle" id="typehead"
                                    placeholder="Search for results..." autoComplete="off" data-bs-toggle="dropdown"
                                    />
                                {/*
                                For Suggestions
                                <div className="dropdown-menu"><button type="button" className="dropdown-item"
                                        data-label="Denmark" data-value="DK"><span
                                            className="text-primary">D</span>enmark</button><button type="button"
                                        className="dropdown-item" data-label="Djibouti" data-value="DJ"><span
                                            className="text-primary">D</span>jibouti</button><button type="button"
                                        className="dropdown-item" data-label="Dominica" data-value="DM"><span
                                            className="text-primary">D</span>ominica</button><button type="button"
                                        className="dropdown-item" data-label="Dominican Republic (the)"
                                        data-value="DO"><span className="text-primary">D</span>ominican Republic
                                    </button>
                                </div>  */}
                                <button className="btn px-0 pt-2"><i className="fe fe-search"
                                        aria-hidden="true"></i></button>
                            </div>
                            <div className="d-flex order-lg-2 ms-auto header-right-icons">
                                <div className="dropdown d-none"> <Link to="#" className="nav-link icon"
                                        data-bs-toggle="dropdown"> <i className="fe fe-search"></i> </Link>
                                    <div className="dropdown-menu header-search dropdown-menu-start">
                                        <div className="input-group w-100 p-2"> <input type="text" className="form-control"
                                                placeholder="Search...."/>
                                            <div className="input-group-text btn btn-primary"> <i className="fe fe-search"
                                                    aria-hidden="true"></i> </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="navbar-toggler navresponsive-toggler d-lg-none ms-auto" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"
                                    aria-controls="navbarSupportedContent-4" aria-expanded="false"
                                    aria-label="Toggle navigation"> <span
                                        className="navbar-toggler-icon fe fe-more-vertical"></span> </button>
                                <div className="navbar navbar-collapse responsive-navbar p-0">
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                                        <div className="d-flex order-lg-2">
                                            <div className="dropdown d-lg-none d-flex"> <Link to="#"
                                                    className="nav-link icon" data-bs-toggle="dropdown"> <i
                                                        className="fe fe-search"></i> </Link>
                                                <div className="dropdown-menu header-search dropdown-menu-start">
                                                    <div className="input-group w-100 p-2"> <input type="text"
                                                            className="form-control" placeholder="Search...."/>
                                                        <div className="input-group-text btn btn-primary"> <i
                                                                className="fa fa-search" aria-hidden="true"></i> </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex country"> <Link to=""
                                                    className="nav-link icon theme-layout nav-link-bg layout-setting"> <span
                                                        className="dark-layout"><i className="fe fe-"></i></span> <span
                                                        className="light-layout"><i className="fe fe-sun"></i></span> </Link>
                                                
                                            
                                                        <Notification/>
                                                        {/* <Message/> */}
                                                        <UserMenu/>
                                           
                                          
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="demo-icon nav-link icon"> 
                                    <i className="fe fe-settings fa-spin  text_primary"></i> 
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
              

                    </div>
        {/* <div className="jumps-prevent active"></div>  */}
</>
        )
    

}

export default Header;