import React from "react";
import { Route, BrowserRouter as Router, Routes as Switch, Navigate} from 'react-router-dom'
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import DashboardPage from "./Pages/DashboardPage";
import FinancePage from "./Pages/FinancePage";
import "./App.css"
import "./Utils/css/custom.css"
import StreetPage from "./Pages/StreetPage";
import ConfigurationPage from "./Pages/ConfigurationPage";
import VehicleType from "./Pages/VehicleType";
import VehiclePage from "./Pages/VehiclesPage";
import WorkingHoursPage from "./Pages/WorkingHoursPage";
import WorkingDaysPage from "./Pages/WorkingDaysPage";
import InvoicePage from "./Pages/InvoicePage";
import LocationPage from "./Pages/LocationPage";
import UsersPage from "./Pages/UsersPage";
import PublicHolidayPage from "./Pages/PublicHolidayPage";
import ClampThresholdPage from "./Pages/ClampThresholdPage";
import SessionPage from "./Pages/SessionPage";
import ParkingPage from "./Pages/ParkingPage";
import SessionAnalysis from "./Pages/SessionAnalysis";
import VehicleProfile from "./Pages/VehicleProfile";
import OutstandingInvoices from "./Pages/OutstandingInvoices";







class App extends React.Component{

  state={
    loggedIn:false
  }

  componentDidMount() {
     this.checkLogin();
  }

  checkLogin()
  {
    
    if(!window.localStorage.getItem("scpps@user"))
    {
      this.setState({
        loggedIn:false
      })
    }else{
      this.setState({
        loggedIn:true
      })
    }
  }


  render(){

    return (
      <div>
         <Router forceRefresh={false}>
            <Switch>
                {!this.state.loggedIn && <>
                  <Route path="*" element={<Login/>}/> 
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/" element={!this.state.loggedIn?<Navigate replace to="/login" />:<DashboardPage/>}/>
                </>}
                {this.state.loggedIn && <>
                  <Route path="/" element={!this.state.loggedIn?<Navigate replace to="/login" />:<DashboardPage/>}/>
                  <Route path="/login" element={this.state.loggedIn?<Navigate replace to="/" />:<Login/>}/>
                  <Route path="/dashboard" element={<DashboardPage/>}/>
                  <Route path="/finance" element={<FinancePage/>}/>
                  <Route path="/streets" element={<StreetPage/>}/>
                  <Route path="/location" element={<LocationPage/>}/>
                  <Route path="/configuration" element={<ConfigurationPage/>}/>
                  <Route path="/vehicletype" element={<VehicleType/>}/>
                  <Route path="/vehiclelist" element={<VehiclePage/>}/>
                  <Route path="/working-hours" element={<WorkingHoursPage/>}/>
                  <Route path="/working-days" element={<WorkingDaysPage/>}/>
                  <Route path="/invoice" element={<InvoicePage/>}/>
                  <Route path="/users" element={<UsersPage/>}/>
                  <Route path="/public-holiday" element={<PublicHolidayPage/>}/>
                  <Route path="/clamp-threshold" element={<ClampThresholdPage/>}/>
                  <Route path="/graph" element={<SessionAnalysis />}/>
                  
                  <Route path="/session" element={<SessionPage/>}/>
                  <Route path="/parking" element={<ParkingPage/>}/>
                  <Route path="/vehicle-profile/:id" element={<VehicleProfile />}/>


                  <Route path="/vehicle/balances" element={ <OutstandingInvoices/>}/>




                  
                  <Route path="/login" element={<Login/>}/> 
                  <Route path="/user/signup" element={<Signup/>}/>
                  </>}
              </Switch>
          </Router>
      </div>
    )
  }

}

export default App;