import React from 'react'
import AlertError from "../Components/Common/AlertError";
import AlertSuccess from "../Components/Common/AlertSuccess";
import TextInput from "../Components/Common/TextInput";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxPublicHoliday from "../Utils/remote/ajaxPublicHoliday";


class PublicHolidayPage extends React.Component{
state = {
    holidayName:"",
    holidayDate:"",
    holidayListing:false,
    info:""
}

componentDidMount(){
    this.listPublicHoliday()
    }

    listPublicHoliday=async()=>{
        const server_response = await ajaxPublicHoliday .getPublicHoliday();
        if(server_response.status==="OK")
        {
            this.setState({
                holidayListing:server_response.details
            })
        }

    }

    handleHolidayName=(e)=>{
        this.setState({holidayName: e.target.value})
    }

    handleHolidayDate=(e)=>{
        this.setState({holidayDate: e.target.value})
    }

    createPublicHoliday = async(event)=>{

        event.preventDefault();

        const { holidayName, holidayDate} = this.state;

        if(holidayName.length>0)
        {
            //post to api
            const server_response = await ajaxPublicHoliday.createPublicHoliday(holidayName, holidayDate);
            if(server_response.status==="OK")
            {
                this.setState({
                    holidayName:"",
                    holidayDate:"",
                    info: <AlertSuccess message={server_response.message}/>
                })
                this.listPublicHoliday();
            }

        }else{

            this.setState({
                info: <AlertError message="Complete all fields and try again"/>
            })
            //error
        }

    }


    render(){
        return(
            <AppContainer
            title="Public Holidays"
           >
               <div className="container">
               <button type="button" class="btn btn-info btn-md" style={{float:"right",fontSize:"14px",color:"yellow !important"}} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      Add Holiday <i className="fa fa-plus"></i>
    </button>

       

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add holiday</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      {this.state.info}
      <form method="post" onSubmit={this.createPublicHoliday}>

      <div class="form-group">
    {/* <TextInput
        type="text"
        label='Holiday Name'
        value={this.state.holidayName}
        placeholder="Enter Street name"
        onChange={()=>this.handleHolidayName}
        className="form-control mb-4"
        /> */}
        <TextInput
    type="text"
    label='Location Name'
    value={this.state.holidayName}
    placeholder="Enter Holiday name"
    onChange={()=>this.handleHolidayName}
    />
     </div>
     <div class="form-group">
 <TextInput
     type="date"
     style={{marginBottom:"20px"}}
     label='Holiday Date'
     value={this.state.holidayDate}
     placeholder=""
     onChange={()=>this.handleHolidayDate}
     />
     </div>
     <div class="modal-footer mt-4">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-success">Create</button>
      </div>
 </form>

      </div>
      
    </div>
  </div>
</div>
            <div className="table-responsive">
                <table class="table table-light mt-5">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Event Name</th>
                            <th>Event Date</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {Array.isArray(this.state.holidayListing) && this.state.holidayListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item.event_name}</td>
                                <td>{item.event_date}</td>
                                <td>{item.created_at}</td>
                                <td><button type="submit" className="btn btn-sm btn-info">Update</button></td>
                            </tr>
                            
                        )}

                    </tbody>
                </table>
            </div>


               </div>
           </AppContainer>
        )
    }
}

export default PublicHolidayPage