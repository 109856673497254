import React from 'react'
import HomeCard from '../Components/HomeCard';
import AppContainer from "../Components/Structure/AppContainer";
import ajaxLocation from '../Utils/remote/ajaxLocation';
import ajaxStreet from '../Utils/remote/ajaxStreet';
import ajaxUser from '../Utils/remote/ajaxUser';




class ConfigurationPage extends React.Component{

    state = {
        countStreets:false,
        countLocations:false,
        countUsers:false
    }
    
    componentDidMount(){
        this.countStreet()
        this.countLocation()
        this.countUsers()
        
    }

    countStreet=async()=>{

        const server_response = await ajaxStreet.countStreet();
        if(server_response.status==="OK")
        {
            this.setState({
                countStreets:server_response.details
                
            })
            
        }
    }
    countLocation=async()=>{
        const server_response = await ajaxLocation.countLocation();
        if(server_response.status==="OK")
        {
            this.setState({
                countLocations:server_response.details
            })
         
        }
    
    }
    

    countUsers=async()=>{
        const server_response = await ajaxUser.countUsers();
        if(server_response.status==="OK")
        {
            this.setState({
                countUsers:server_response.details
            })
         
        }
    
    }

    render(){
        return(
            <AppContainer title="Configurations">
            <HomeCard 
            title="Total Streets" 
            value={this.state.countStreets.total}
            icon="fa fa-road"
            color="success"
            bottomDescription=""
            bottomValue=""
            link="/streets"

            />
            <HomeCard 
            title="Total Locations" 
            value={this.state.countLocations.total}
            icon="fas fa-map-marker-alt"
            color="warning"
            bottomDescription=""
            bottomValue=""
            link="/location"
            />

            <HomeCard 
            title="Total Users" 
            value={this.state.countUsers.total}
            icon="fa fa-user"
            color="secondary"
            bottomDescription=""
            bottomValue=""
            link="/users"
            />

            <HomeCard 
            title="Clamp Threshold" 
            value="20,000"
            icon="fas fa-link"
            color="danger"
            bottomDescription=""
            bottomValue=""
            link="/clamp-threshold"
            />

            <HomeCard 
            title="Public Holidays" 
            value="02"
            icon="fas fa-calendar-check"
            color="primary"
            bottomDescription=""
            bottomValue=""
            link="/public-holiday"
            />

            <HomeCard 
            title="Vehicle Types" 
            value="02"
            icon="fa fa-car-alt"
            color="info"
            bottomDescription=""
            bottomValue=""
            link="/vehicletype"
            />

            <HomeCard 
            title="Working Hours" 
            value="10"
            icon="fa fa-clock"
            color="info"
            bottomDescription=""
            bottomValue=""
            link="/working-hours"
            />
            <HomeCard 
            title="Working Days" 
            value="05"
            icon="fa fa-calendar-week"
            color="info"
            bottomDescription=""
            bottomValue=""
            link="/working-days"
            />

            
            </AppContainer>
        )
        }
}

export default ConfigurationPage