import React from 'react'
import { Link } from 'react-router-dom';
import AppContainer from '../Components/Structure/AppContainer';
import ajaxVehicle from '../Utils/remote/ajaxVehicle';

class VehiclesPage extends React.Component{
    state={
        vehicleId:"",
        vehicleListing:false
    }


    componentDidMount(){
            this.listVehicle();
    }


    setVehicle=(e)=>{
        this.setState({vehicleId:e.value})
    }

    handleVehicleChange=(e)=>{

    }


    listVehicle=async()=>{

        const server_response = await ajaxVehicle.getVehicle();
        if(server_response.status==="OK")
        {
            this.setState({
                vehicleListing:server_response.details
            })
        }
        console.log(server_response)

    }

    render(){


        return(
           <AppContainer
            title="Registered Vehicles"
           >

            {/* {!this.state.vehicleListing && <ActivityLoader size={10} inline/> }
            <ActivityLoader height={100} width={50}/> */}
        

            <div className="row">

                <div className="col-12 card">

                <table border={1} className="table table-striped">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Plate Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(this.state.vehicleListing) && this.state.vehicleListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <Link to="/streets">
                                
                                <td>{item.plate_number}</td>
                                </Link>
                            </tr>
                        )}

                    </tbody>
                </table>
                </div>

            </div>

               

           </AppContainer>
        )
    }
}

export default VehiclesPage