import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('scpps@user');
const apiHost=dictionary.apiHost

const ajaxVehicleType={

   async getVehicleType() {
     try {
       let response = await fetch(apiHost + "vehicle/list_vehicle_type",
         {
           method: 'GET',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           }
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   }

//    async createStreet(streetName) {
//      let data = {
//        street_name: streetName
//      }
//      try {
//        let response = await fetch(apiHost + 'street/create',
//          {
//            method: 'POST',
//            headers: {
//                'Authorization': 'Bearer ' + ACCESS_TOKEN,
//                'Accept': 'application/json',
//                'Content-Type': 'application/json'
//            },
//            body: JSON.stringify(data),
//          });
//        let result = await response.json();
//        return result;
//      } catch (error) {
//        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
//        return connError;
//      }
//    }

      

}


export default ajaxVehicleType
