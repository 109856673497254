import React from "react";
import { Link } from "react-router-dom";

class Signup extends React.Component{

    render(){

                return (
                  <div id="ff">
                    <p>Signup Page</p>
                    <p><Link to="/signup">Signup Page</Link></p>
                  </div>
                )
              
        
    }
}

export default Signup;