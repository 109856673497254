import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate,useParams } from "react-router-dom";
import ajaxVehicle from "../Utils/remote/ajaxVehicle";
import ajaxVehicleProfile from "../Utils/remote/ajaxVehicleProfile";
import ActivityLoader from "../Components/Common/ActivityLoader";
import AppContainer from "../Components/Structure/AppContainer";
import TextInput from "../Components/Common/TextInput";
import AlertSuccess from "../Components/Common/AlertSuccess";
import AlertError from "../Components/Common/AlertError";
import ajaxInvoice from "../Utils/remote/ajaxInvoice";

export default function VehicleProfile() {
    const [ownerid, setOwnerID] = useState("");
    const [balance, setBalance] = useState("");
    const [vehicleId, setvehicleId] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [info, setInfo] = useState("");

    const [vehicleInfo, setVehicleInfo] = useState(false);
    const {id} = useParams();
    console.log(id)

    useEffect(()=>{
        listVehicleDetails(id);
    },[]);

    useEffect(()=>{
        listVehicleInvoices();
    },[vehicleId]);
    
    
    const listVehicleInvoices=async()=>{

        const server_response = await ajaxInvoice.getVehicleInvoice(vehicleId);
        console.log(server_response.details)

        if(server_response.status==="OK")
        {
            setBalance(server_response.details.amount_c)
    
        }

    }
    // const listVehicleInvoices=async()=>{

    //     const server_response = await ajaxInvoice.getVehicleInvoice(vehicleId);
    //     console.log(server_response);
    //     if(server_response.status==="OK")
    //     {
    //     //    setVehicleInfo(server_response.details)
    //     //    setOwnerID(server_response.details.owner.id)
    //     //    setFirstname(server_response.details.owner.first_name)
    //     //    setLastname(server_response.details.owner.last_name)
    //     //    setPhoneNumber(server_response.details.owner.phone_number)
    //     //    setvehicleId(server_response.details.id)
    //     }
    // }

   const listVehicleDetails=async(id)=>{

        const server_response = await ajaxVehicleProfile.getVehicleDetails(id);
        console.log(server_response);
        if(server_response.status==="OK")
        {
           setVehicleInfo(server_response.details)
           setOwnerID(server_response.details.owner.id)
           setFirstname(server_response.details.owner.first_name)
           setLastname(server_response.details.owner.last_name)
           setPhoneNumber(server_response.details.owner.phone_number)
           setvehicleId(server_response.details.id)
        }
    }
 
    const createVehicleProfile= async(event) => {
        event.preventDefault();

        if(firstname.length>0 && lastname.length>0 && phone_number>0 && ownerid>0)
        {
            //post to api
            const server_response = await ajaxVehicleProfile.updateVehicleDetails(firstname,lastname,phone_number,ownerid);
            console.log(server_response);
            if(server_response.status==="OK")
            {
               
                setInfo(<AlertSuccess message={server_response.message}/>);
            }
            listVehicleDetails();


        }else{

            setInfo(<AlertError message="Complete all fields and try again"/>);

        }
    }


 const handleFirstName = (event) =>{
    setFirstname(event.target.value)
}

     const handleLastName = (event) =>{
         setLastname(event.target.value)
     }

     const handlePhoneNumber = (event) =>{
        setPhoneNumber(event.target.value)
    }


    return (
  
        <AppContainer
            title="Vehicle Profile"
           >
               
        <div className="container">
<div className="row">
                   <div className="col-md-12">
                   <button type="button" class="btn btn-info btn-md mb-4" style={{float:"right",fontSize:"14px",color:"yellow !important"}} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      Edit Profile 
      <i className="fa fa-plus"></i>
    </button>
                   </div>
               </div>


       

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Update Vehicle Profile</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      {info}
      <form method="post" onSubmit={createVehicleProfile}>
           
        <TextInput
            type="text"
            label='First Name'
            value={firstname}
            placeholder="First Name"
            name="FirstName"
            onChange={handleFirstName}
            />
            <TextInput
            type="text"
            label='Last Name'
            value={lastname}
            placeholder="Last Name"
            name="lastname"
            onChange={handleLastName}
            />
            <TextInput
            type="text"
            label='Phone Number'
            value={phone_number}
            placeholder="Phone Number"
            name="phone_number"
            onChange={handlePhoneNumber}
            />


     <div class="modal-footer mt-4">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-success">Update profile</button>
      </div>
 </form>

      </div>
      
    </div>
  </div>
</div>
            {!vehicleInfo && <ActivityLoader/>}
        {vehicleInfo && <div className="row">
            <div className="col-md-8">
            <div class="card">
                <div class="card-header">
                   <b> Vehicle Owner Details</b>
                </div>
                <div class="card-body">
                  
                    <div className="row">
                   {/* {vehicleInfo.owner && Object.keys(vehicleInfo).map((index, key)=>{ */}
                     
                     <div>
            
                    <div class="col-md-6 mb-3">
                    <label for="exampleFormControlInput1" class="form-label">First Name</label>
                    <p>{vehicleInfo.owner.first_name}</p>
                    </div>
                    <div class="col-md-6 mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Last Name</label>
                    <p>{vehicleInfo.owner.last_name}</p>
                    </div>
                    <div class="col-md-12 mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                    <p>{vehicleInfo.owner.phone_number}</p>
                    </div>
                    
                    </div>
                    </div>
                    </div>
            </div>
            </div>
         
            <div className="col-md-4">
            <div class="card">
                <div class="card-header">
                    <b>Vehicle Details</b>
                </div>
                <div class="card-body">
                <div class="col-md-12 mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Vehicle Type</label>
                    <p>{vehicleInfo.vehicle_type.type_name}</p>
                </div>
                <div class="col-md-12 mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Plate Number</label>
                    <p>{vehicleInfo.plate_number}</p>
                </div>
                <div class="col-md-12 mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Vehicle Balance</label>
                    <p>UGX {balance}</p>
                    </div>
            </div>
            </div>
            </div>
        </div>}
           </div>
            
           </AppContainer>


    )
}