import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('scpps@user');
const apiHost=dictionary.apiHost

const ajaxPublicHoliday={

   async getPublicHoliday() {
     try {
       let response = await fetch(apiHost + 'public-holiday/list',
         {
           method: 'GET',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           }
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   },

   

async createPublicHoliday(event_name, event_date) {
    let data = {
      "event_name": event_name,
      "event_date": event_date
    }
    try {
      let response = await fetch(apiHost + 'public-holiday/create',
        {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  }
}

export default ajaxPublicHoliday