import React from 'react';
import { Link } from 'react-router-dom';
import InitialAvatar from '../Common/InitialAvatar';

function UserMenu(props) {


    const onLogout=()=>{
        localStorage.removeItem('scpps@user');
        window.location.reload();
    }

    return (
        <div className="dropdown d-flex profile-1"> <Link to="#"
        data-bs-toggle="dropdown" className="nav-link leading-none d-flex"
        aria-expanded="false">
        <InitialAvatar value="SA" background="bg-primary" size={1}/>
            </Link>
    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        <div className="drop-heading">
            <div className="text-center">
                <h5 className="text-dark mb-0 fs-14 fw-semibold">System Admin
                </h5> <small className="text-muted">Senior Admin</small>
            </div>
        </div>
        <div className="dropdown-divider m-0"></div> 
                <Link className="dropdown-item" to="#" onClick={ onLogout }> <i
                className="dropdown-icon fe fe-alert-circle"></i> Sign out </Link>
    </div>
</div>
    );
}

export default UserMenu;