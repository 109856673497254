import React from "react";
import HomeCard from "../Components/HomeCard";
import AvailableSpaceCard from "../Components/Operations/AvailableSpaceCard";
import RecentSessions from "../Components/Operations/RecentSessions";
import SessionCard from "../Components/Operations/SessionCard";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxVehicle from "../Utils/remote/ajaxVehicle";
import FeesGraph from "./SessionAnalysis";
class DashboardPage extends React.Component{

state = {
    countSession:false,
    countVehicle:false,
    totalVehicles:'...',
    totalNewVehicles:'...'
}

componentDidMount(){
    this.countVehicle()
    this.countNewVehicle();
}



countVehicle=async()=>{

    const server_response = await ajaxVehicle.countVehicle();
    if(server_response.status==="OK")
    {
        this.setState({
            totalVehicles:server_response.details.total_p
        })
    }
}


countNewVehicle=async()=>{

    const server_response = await ajaxVehicle.countNewVehicle();
    if(server_response.status==="OK")
    {
        this.setState({
            totalNewVehicles:server_response.details.total_p
        })
    }
}





    render(){
        return(
           <AppContainer
            title="Dashboard"
           >
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                        <div class="row">

                        <SessionCard/>

                        <AvailableSpaceCard/>
                        <HomeCard 
                            title="Total Vehicles" 
                            value={this.state.totalVehicles}
                            icon="fa fa-car"
                            color="danger"
                            bottomDescription="Added today"
                            bottomValue={this.state.totalNewVehicles}
                            link="#"
                            />


                    <div className="col-md-6 col-sm-12">
                        <RecentSessions/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FeesGraph/>

                    </div>
                </div>
            </div>
                  

           </AppContainer>
        )
    }
}

export default DashboardPage