import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('scpps@user');
const apiHost=dictionary.apiHost

const ajaxSession={

    async startSession(plateNumber) {
        let data = {
          plate_number: plateNumber,
        }
        try {
          let response = await fetch(apiHost + 'session/start',
            {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },
      async countSession() {
        try {
          let response = await fetch(apiHost + 'session/count',
            {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async getSession() {
        try {
          let response = await fetch(apiHost + 'session/list',
            {
              method: 'GET',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
                
              });
              let result = await response.json();
              return result;
            } catch (error) {
              let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
              return connError;
            }
          },




      async countActiveSessions() {
        try {
          let response = await fetch(apiHost + 'session/active/count',
            {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },


      async getRecentSessions() {
        try {
          let response = await fetch(apiHost + 'session/recent/list',
            {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      },

      async countAvailableParking() {
        try {
          let response = await fetch(apiHost + 'parking/available/count',
            {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      }
      ,


      async getWeeklySessionStatistics() {
        try {
          let response = await fetch(apiHost + 'session/week',
            {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      }

}


export default ajaxSession
