import React from "react";
import ActivityLoader from "../Components/Common/ActivityLoader";
import TextInput from "../Components/Common/TextInput";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxStreet from "../Utils/remote/ajaxStreet";

class WorkingDaysPage extends React.Component{

    render(){
        return(
        <AppContainer title="Work Days">
            <div className="container">
            <div className="card">
                <div className="card-header">
                    <h5>Toggle on or off to select working/operating days.</h5>
                </div>
                <div className="card-body" style={{fontSize:"18px",marginLeft:"20px"}}>
                <div className="form-check form-switch">
            <input className="form-check-input form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
            <label className="form-check-label" for="flexSwitchCheckChecked">Sunday</label>
            </div>
            <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
            <label className="form-check-label" for="flexSwitchCheckChecked">Monday</label>
            </div>
            <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
            <label className="form-check-label" for="flexSwitchCheckChecked">Tuesday</label>
            </div>
            <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
            <label className="form-check-label" for="flexSwitchCheckChecked">Wednesday</label>
            </div>
            <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
            <label className="form-check-label" for="flexSwitchCheckChecked">Thursday</label>
            </div>
            <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
            <label className="form-check-label" for="flexSwitchCheckChecked">Friday</label>
            </div>
            <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
            <label className="form-check-label" for="flexSwitchCheckChecked">Saturday</label>
            </div>
                </div>
                </div>
            </div>
            
        </AppContainer>
        )
    }
}

export default WorkingDaysPage
