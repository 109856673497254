import React from "react";
import AppContainer from "../Components/Structure/AppContainer";
import ajaxInvoice from "../Utils/remote/ajaxInvoice";
import ActivityLoader from "../Components/Common/ActivityLoader";
import TableHeader from "../Components/Common/TableHeader";
import { Link } from "react-router-dom";
class OutstandingInvoices extends React.Component{

    state={
        invoiceListing:false,
    }


    componentDidMount(){
            this.listInvoice()
            
    }


    
    listInvoice=async()=>{

        const server_response = await ajaxInvoice.fetchOutstandingInvoices();
        if(server_response.status==="OK")
        {
            this.setState({
                invoiceListing:server_response.details
            })


        }else{
            this.setState({
                invoiceListing:false
            })
        }

    }

    

    render(){


      

        return(
           <AppContainer
            title="Outstanding Invoices"
           >
           
            <div className="container">
 
           

            <div className="border-top mt-3"></div>
            <div className="user-manager scroll-widget border-top overflow-auto">


                <div className="table-responsive ">
                <table border={3} className="table table-light table-hover table-bordered border-light mt-4">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Vehicle No.</th>
                            <th>Balance</th>
                            <th>Phone Number</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(this.state.invoiceListing) && this.state.invoiceListing.map((item, key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td><Link to={`/vehicle-profile/${item.vehicle.plate_number}`}>{item.vehicle.plate_number}</Link></td>
                                <td>{item.balance.total_c}</td>
                                <td></td>
                            </tr>
                            
                        )}

                    </tbody>
                </table>
                {!this.state.invoiceListing && <ActivityLoader/>}
                </div>
                
                </div>


            </div>

               

           </AppContainer>
        )
    }
}

export default OutstandingInvoices