import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('scpps@user');
const apiHost=dictionary.apiHost

const ajaxWorkingHours={

   async createWorkingHours(openingTime,closingTime) {
     let data = {
       opening_time: openingTime,
       closing_time: closingTime
     }
     try {
       let response = await fetch(apiHost + 'working-hours/create',
         {
           method: 'POST',
           headers: {
               'Authorization': 'Bearer ' + ACCESS_TOKEN,
               'Accept': 'application/json',
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
         });
       let result = await response.json();
       return result;
     } catch (error) {
       let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
       return connError;
     }
   }
}


export default ajaxWorkingHours
